<template>
  <h1>Rules</h1>
</template>

<script>
export default {
  title: 'Rules',
  mounted() {
    this.$emit('update-title', "Logs")
  },
}
</script>
