var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"items-per-page":100,"dense":"","search":_vm.search,"sort-by":_vm.headers[0].value,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',[(_vm.filterTitle)?_c('v-select',{attrs:{"label":_vm.filterTitle,"items":_vm.filterKeys},model:{value:(_vm.filterValue),callback:function ($$v) {_vm.filterValue=$$v},expression:"filterValue"}}):_vm._e()],1),_c('v-col',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(_vm._s(_vm.name))])],1),_c('v-col',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('formatter') ||
  header.hasOwnProperty('icon') ||
  header.hasOwnProperty('args') ||
  header.text === 'Name'; }
  )),function(header,index){return {key:("item." + (header.value)),fn:function(ref){
  var header = ref.header;
  var value = ref.value;
return [(header.hasOwnProperty('formatter'))?_c('span',{key:index},[_vm._v(" "+_vm._s(header.formatter(value))+" ")]):_vm._e(),(header.text === 'Name')?_c('span',{key:index},[(value.match('^sequence\.'))?_c('a',{on:{"click":function($event){return _vm.run_sequence(value)}}},[_vm._v(_vm._s(value))]):_c('span',[_vm._v(_vm._s(value))])]):(header.hasOwnProperty('icon'))?_c('span',{key:index},[_c('v-icon',{key:index,attrs:{"color":header.icon[value].color}},[_vm._v(_vm._s(header.icon[value].icon))])],1):(header.hasOwnProperty('args'))?_c('span',{key:index},[_c('Args',{attrs:{"title":header.text,"args":value}})],1):_vm._e()]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }