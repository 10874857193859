var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":"","color":"indigo","dark":"","height":"50"}},[_c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',[_c('v-toolbar-title',[_vm._v("AppDaemon "+_vm._s(_vm.version))])],1),_c('v-col',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('v-col',[_c('v-card-title',{staticClass:"justify-end"},[(_vm.connected)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-lan-connect")])]}}],null,false,1647777282)},[_c('span',[_vm._v("Connected")])])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-lan-disconnect")])]}}])},[_c('span',[_vm._v("Disconnected")])])],_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.menu),function(entry){return _c('v-list-item',{key:entry.option,on:{"click":entry.callback}},[_c('v-list-item-title',[_vm._v(_vm._s(entry.option))])],1)}),1)],1)],2)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.about),callback:function ($$v) {_vm.about=$$v},expression:"about"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_c('span',[_vm._v("About AppDaemon")])]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{staticClass:"ma-5",attrs:{"height":"50","width":"50","src":"appdaemon.png"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-card-title',{staticClass:"black--text"},[_vm._v(" AppDaemon ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-text',{staticClass:"black--text"},[_vm._v(" Icons made by "),_c('a',{attrs:{"href":"http://www.freepik.com","title":"Freepik"}},[_vm._v("Freepik")]),_vm._v(" from "),_c('a',{attrs:{"href":"http://www.flaticon.com","title":"Flaticon"}},[_vm._v("www.flaticon.com")]),_vm._v(" is licensed by "),_c('a',{attrs:{"href":"http://creativecommons.org/licenses/by/3.0/","title":"Creative Commons BY 3.0","target":"_blank"}},[_vm._v("CC 3.0 BY")])])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.about = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }