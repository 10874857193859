<template>
  <h1>Sequences</h1>
</template>

<script>
export default {
  title: 'Sequences',
    mounted()
  {
        this.$emit('update-title', "Sequences")
  },
}
</script>
