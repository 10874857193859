<template>
  <v-app>
    <AUI/>
  </v-app>
</template>

<script>
import AUI from './components/AUI'

export default {
  name: "adui",
  components: {
    AUI,
  },
}
</script>